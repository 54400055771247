<template>
  <ReportsPaginated
    titleKey="report_customize_login_title"
    :companyView="companyView"
    :getterUrl="getterUrl"
    :userEmail="userEmail"
    @tableOptions="tableOptions = $event"
    :contexts="['customize_login']"
    :headers="headers"
  />
</template>
<script>
import ReportsPaginated from "@/components/logs/ReportsPaginated.vue";

export default {
  name: "ReportsCustomizeLogin",
  components: { ReportsPaginated },
  props: {
    userEmail: { type: String },
    companyView: { type: Boolean, default: () => true },
  },
  data: () => {
    return {
      getterUrl: `${process.env.VUE_APP_API_BASE_URL}/logs/users`,
      headers: [
        {
          value: "date",
          width: 180,
          sortable: false,
        },
        {
          value: "author_user",
          sortable: false,
        },
        {
          value: "description",
          sortable: false,
        },
        {
          value: "ip",
          width: 170,
          sortable: false,
        },
        {
          value: "browser",
          width: 160,
          sortable: false,
        },
        {
          value: "operational_system",
          width: 186,
          sortable: false,
        },
      ],
    };
  },
};
</script>
